import { useEffect } from 'react';
import './../App.css';

function Footer(){

    useEffect(() => {
        return () => {
        };
    }, [])

    return(
        <>
            <div className='footer'>
                <div className='footer__con'>
                    <div className='footer__logo'>
                        {/* <img src='/images/logo.png' decoding="async"/> */}
                        주식회사 뮤앤미
                    </div>
                    <hr/>
                    <div className='footer__txt-con'>
                        <div className='footer__txt01'>
                            주식회사 뮤앤미 | 사업자등록번호 533-86-03404 | 개인정보 보호 책임자 : 조수빈
                        <br/>
                            주소 : 부산광역시해운대구반여로96,2층8호(반여동,영풍빌딩)
                        <br/>
                            대표자 : 전서연 | 대표번호 : 070-4264-9257 | 이메일 : meownme@naver.com
                        <br/>
                        </div>
                        <div className='footer__txt02'>
                            Meow&Me Inc. All Rights Reserved.
                        </div>
                    </div>
                    {/* <ul className='footer__icon-con'>
                        <a href='https://www.instagram.com/e.un_edu_/'><img src='/icons/sns01.png' /></a>
                        <a href='https://www.youtube.com/@user-bx5bv3xu4s/featured'><img src='/icons/sns02.png' /></a>
                    </ul> */}
                    <div className='footer__law-con'>
                        <a href='https://quiver-fish-2ee.notion.site/344a7e8fcbb5483f810ee9ef545cd3e0?pvs=4'>이용약관</a>
                        <a href='https://quiver-fish-2ee.notion.site/1662e552816b4fd5a9a0a5e62a983d62?pvs=4'>유료서비스 이용약관</a>
                        <a href='https://quiver-fish-2ee.notion.site/6b47fa3d458245a2abc8b68a8fa50871?pvs=4'>개인정보처리방침</a>
                        <a href='/license'>라이선스</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;