import { useEffect, useRef, useState } from "react";
import { loadPaymentWidget, ANONYMOUS } from "@tosspayments/payment-widget-sdk";
import './style.css';

const generateRandomString = () => window.btoa(Math.random()).slice(0, 20);

export function CheckoutPage(props) {
  const forProd = process.env.NODE_ENV !== 'development';

  const userData = props.userData;
  const paymentWidgetRef = useRef(null);
  const paymentMethodsWidgetRef = useRef(null);
  const agreementWidgetRef = useRef(null);
  const [price, setPrice] = useState(localStorage.getItem('price'));
  const [number, setNumber] = useState(localStorage.getItem('number_of_churr'));

  useEffect(() => {
    (async () => {
      // const paymentWidget = await loadPaymentWidget(forProd ? 'live_gck_d46qopOB89dGxZ75AYaLrZmM75y0' : "test_gck_24xLea5zVAlODbn1nEMx3QAMYNwW",  ANONYMOUS); // 비회원 customerKey
      const paymentWidget = await loadPaymentWidget(forProd ? 'live_gck_KNbdOvk5rk40d4veYPeq8n07xlzm' : "test_gck_0RnYX2w532Yk59K41wal8NeyqApQ",  ANONYMOUS); // 비회원 customerKey

      if (paymentWidgetRef.current == null) {
        paymentWidgetRef.current = paymentWidget;
      }

      /**
       * 결제창을 렌더링합니다.
       * @docs https://docs.tosspayments.com/reference/widget-sdk#renderpaymentmethods%EC%84%A0%ED%83%9D%EC%9E%90-%EA%B2%B0%EC%A0%9C-%EA%B8%88%EC%95%A1
       */
      const paymentMethodsWidget = paymentWidgetRef.current.renderPaymentMethods(
        "#payment-method",
        { value: price },
        { variantKey: "DEFAULT" }
      );

      /**
       * 약관을 렌더링합니다.
       * @docs https://docs.tosspayments.com/reference/widget-sdk#renderagreement%EC%84%A0%ED%83%9D%EC%9E%90-%EC%98%B5%EC%85%98
       */
      agreementWidgetRef.current = paymentWidgetRef.current.renderAgreement('#agreement', { variantKey: 'DEFAULT' });

      paymentMethodsWidgetRef.current = paymentMethodsWidget;
    })();
  }, []);

  return (
    <div className="wrapper w-100">
      <div className="max-w-540 w-100">
        <div id="payment-method" className="w-100" />
        <div id="agreement" className="w-100" />
        <div className="btn-wrapper w-100">
          <button
            className="btn primary w-100"
            onClick={async () => {
              const paymentWidget = paymentWidgetRef.current;

              try {
                /**
                 * 결제 요청
                 * @docs https://docs.tosspayments.com/reference/widget-sdk#requestpayment%EA%B2%B0%EC%A0%9C-%EC%A0%95%EB%B3%B4
                 */
                await paymentWidget?.requestPayment({
                  orderId: generateRandomString(),
                  orderName: `츄르르(${number}개)`,
                  customerName: userData?.email || localStorage.getItem("nickname"),
                  customerEmail: userData?.email || 'unsigned',
                  successUrl: window.location.origin + "/toss/serverAuth",
                  // successUrl: window.location.origin + "/sandbox/success" + window.location.search,
                  failUrl: window.location.origin + "/payment/failure"
                });
              } catch (error) {
                // TODO: 에러 처리
              }
            }}
          >
            결제하기
          </button>
        </div>
      </div>
    </div>
  );
}
